<template>
  <div class="earth">
    <el-button
      class="enter"
      type="primary"
      @click="submitForm()"
      style="z-index: 99999999999"
      >进入大屏</el-button
    >
    <!-- <div class="Navbar"></div> -->
    <Navbar></Navbar>
   <el-button class="back" type="primary" @click="backTo()" v-show="showBack==1" style="z-index: 99999999999" v-cloak>返回</el-button>
    <div id="earth-animation-one" v-show="showEarthNumber==1" @click="changeToEarthTwo()" v-cloak ></div>
    <div id="earth-animation-two" v-show="showEarthNumber==2" v-cloak> </div>
    <div id="earth-animation-three" v-show="showEarthNumber==3" v-cloak>
      <!-- <div class="item" id="step-one-title">碳汇项目开发全流程</div>
      <div class="item-line" id="step-one-line"></div>
      <div class="item-content" id="step-one-box1">
        <span class="content-title">边界确认</span>
        <div class="content-contner" >{{itemData.border}}
        </div>
      </div>

       <div class="item-content1" id="step-one-box2">
        <span class="content-title">碳层划分</span>
        <div class="content-contner" >{{itemData.border2}}
        </div>
      </div>

       <div class="item-content2" id="step-one-box3">
        <span class="content-title">基线碳汇量、项目碳汇量计算</span>
        <div class="content-contner" >{{itemData.border3}}
        </div>
      </div>

           <div class="item-content3" id="step-one-box4">
        <span class="content-title">监测与核查</span>
        <div class="content-contner" >{{itemData.border4}}
        </div>
      </div>
       <div class="item-icon3" id="item-icon3" v-show="showItemFour==1" @click.stop="changeItemNumber4()"></div> -->
    </div>
    <div id="earth-animation-four" v-show="showEarthNumber==4"></div>
    <div id="earth-animation-five" v-show="showEarthNumber==5">
      <div class="earth-animation-five-mask"></div>
    </div>
    <StepTwo v-if="showEarthNumber==5" v-cloak></StepTwo>
    <StepFirst @changeItemNumberEmit='changeItemNumber4' v-if="showEarthNumber==3" v-cloak></StepFirst>
  </div>
</template>

<script>
import $ from "jquery"
import StepTwo from "../../components/StepTwo/StepTwo.vue"
import StepFirst from "../../components/StepFirst/StepFirst.vue"
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  name: "Earth",
  data() {
    return {
showEarthNumber:1,
showBack:0,

showItemNumberOne:0,
showItemNumberTwo:0,
showItemNumberThree:0,
showItemOne:1,
showItemTwo:0,
showItemThree:0,
showItemFour:0
    };
  },
  components:{
    Navbar,
    StepTwo,
    StepFirst
  },
  created() {},
  methods: {
    submitForm() {
      this.$router.replace("/home");
    },
    backTo() {
      if (this.showEarthNumber == 3) {
        this.showEarthNumber = 1
        this.showBack = 0
      }else if(this.showEarthNumber == 5){
        this.showEarthNumber = 3
      }

    },
    changeToEarthTwo() {
      this.showEarthNumber = 2;
this.showBack=1
    },
    changeToEarthFour() {
      this.showEarthNumber = 4;
this.showBack=1
    },
changeItemNumber1(){
this.showItemTwo=1
this.showItemNumberOne=1
$("#item-icon1").fadeIn(1000,()=>{

})
},
changeItemNumber2(){
  this.showItemThree=1
  this.showItemNumberTwo=1
$("#item-icon2").fadeIn(1000,()=>{

})
},
changeItemNumber3(){
  this.showItemFour=1
  this.showItemNumberThree=1
$("#item-icon3").fadeIn(1000,()=>{

})
},
changeItemNumber4(){
  console.log("触发了");
  this.showEarthNumber=4
  this.showBack=1
}
  },
  mounted(){
    this.$nextTick(()=>{
      document.getElementById('earth-animation-two').addEventListener('animationend',()=>{
        // console.log('动画执行完毕');
        this.showEarthNumber=3

      })
      document.getElementById('earth-animation-four').addEventListener('animationend',()=>{
        // console.log('动画执行完毕');
        this.showEarthNumber=5
      })
      $('#item-icon').fadeIn(1000,(params) => {
        
      })
    })
  }
};
</script>

<style scoped>
[v-cloak]{
  display: none !important;
}
.earth {
  position: relative;
  width: 100%;
  height: 100vh;
  /* background-image: url("../../assets/HomeMap/星空.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 9999 !important;
}
/* .Navbar {
  position: relative;
  width: 100vw;
  height: 100px;
  background-image: url("../../assets/Navbar/编组 2@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
} */
.enter {
  position: absolute;
  bottom: 30px;
  width: 140px;
  height: 50px;
  background-image: url("../../assets/Navbar/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  box-shadow: none;
  left: 890px;
}
.back {
  position: absolute;
  top: 50px;
  width: 50px;
  height: 30px;
  background-image: url("../../assets/Navbar/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  box-shadow: none;
  left: 30px;
  z-index: 999;
}
@keyframes earth_one {
  0% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00199.jpg");
  }
  0.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00000.jpg");
  }
  1% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00001.jpg");
  }
  1.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00002.jpg");
  }
  2% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00003.jpg");
  }
  2.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00004.jpg");
  }
  3% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00005.jpg");
  }
  3.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00006.jpg");
  }
  4% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00007.jpg");
  }
  4.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00008.jpg");
  }
  5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00009.jpg");
  }
  5.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00010.jpg");
  }
  6% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00011.jpg");
  }
  6.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00012.jpg");
  }
  7% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00013.jpg");
  }
  7.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00014.jpg");
  }
  8% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00015.jpg");
  }
  8.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00016.jpg");
  }
  9% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00017.jpg");
  }
  9.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00018.jpg");
  }
  10% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00019.jpg");
  }
  10.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00020.jpg");
  }
  11% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00021.jpg");
  }
  11.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00022.jpg");
  }
  12% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00023.jpg");
  }
  12.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00024.jpg");
  }
  13% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00025.jpg");
  }
  13.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00026.jpg");
  }
  14% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00027.jpg");
  }
  14.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00028.jpg");
  }
  15% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00029.jpg");
  }
  15.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00030.jpg");
  }
  16% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00031.jpg");
  }
  16.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00032.jpg");
  }
  17% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00033.jpg");
  }
  17.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00034.jpg");
  }
  18% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00035.jpg");
  }
  18.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00036.jpg");
  }
  19% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00037.jpg");
  }
  19.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00038.jpg");
  }
  20% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00039.jpg");
  }
  20.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00040.jpg");
  }
  21% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00041.jpg");
  }
  21.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00042.jpg");
  }
  22% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00043.jpg");
  }
  22.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00044.jpg");
  }
  23% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00045.jpg");
  }
  23.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00046.jpg");
  }
  24% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00047.jpg");
  }
  24.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00048.jpg");
  }
  25% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00049.jpg");
  }
  25.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00050.jpg");
  }
  26% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00051.jpg");
  }
  26.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00052.jpg");
  }
  27% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00053.jpg");
  }
  27.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00054.jpg");
  }
  28% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00055.jpg");
  }
  28.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00056.jpg");
  }
  29% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00057.jpg");
  }
  29.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00058.jpg");
  }
  30% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00059.jpg");
  }
  30.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00060.jpg");
  }
  31% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00061.jpg");
  }
  31.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00062.jpg");
  }
  32% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00063.jpg");
  }
  32.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00064.jpg");
  }
  33% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00065.jpg");
  }
  33.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00066.jpg");
  }
  34% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00067.jpg");
  }
  34.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00068.jpg");
  }
  35% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00069.jpg");
  }
  35.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00070.jpg");
  }
  36% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00071.jpg");
  }
  36.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00072.jpg");
  }
  37% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00073.jpg");
  }
  37.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00074.jpg");
  }
  38% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00075.jpg");
  }
  38.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00076.jpg");
  }
  39% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00077.jpg");
  }
  39.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00078.jpg");
  }
  40% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00079.jpg");
  }
  40.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00080.jpg");
  }
  41% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00081.jpg");
  }
  41.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00082.jpg");
  }
  42% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00083.jpg");
  }
  42.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00084.jpg");
  }
  43% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00085.jpg");
  }
  43.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00086.jpg");
  }
  44% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00087.jpg");
  }
  44.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00088.jpg");
  }
  45% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00089.jpg");
  }
  45.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00090.jpg");
  }
  46% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00091.jpg");
  }
  46.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00092.jpg");
  }
  47% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00093.jpg");
  }
  47.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00094.jpg");
  }
  48% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00095.jpg");
  }
  48.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00096.jpg");
  }
  49% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00097.jpg");
  }
  49.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00098.jpg");
  }
  50% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00099.jpg");
  }

  50.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00100.jpg");
  }
  51% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00101.jpg");
  }
  51.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00102.jpg");
  }
  52% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00103.jpg");
  }
  52.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00104.jpg");
  }
  53% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00105.jpg");
  }
  53.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00106.jpg");
  }
  54% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00107.jpg");
  }
  54.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00108.jpg");
  }
  55% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00109.jpg");
  }
  55.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00110.jpg");
  }
  56% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00111.jpg");
  }
  56.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00112.jpg");
  }
  57% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00113.jpg");
  }
  57.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00114.jpg");
  }
  58% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00115.jpg");
  }
  58.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00116.jpg");
  }
  59% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00117.jpg");
  }
  59.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00118.jpg");
  }
  60% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00119.jpg");
  }
  60.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00120.jpg");
  }
  61% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00121.jpg");
  }
  61.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00122.jpg");
  }
  62% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00123.jpg");
  }
  62.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00124.jpg");
  }
  63% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00125.jpg");
  }
  63.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00126.jpg");
  }
  64% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00127.jpg");
  }
  64.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00128.jpg");
  }
  65% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00129.jpg");
  }
  65.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00130.jpg");
  }
  66% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00131.jpg");
  }
  66.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00132.jpg");
  }
  67% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00133.jpg");
  }
  67.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00134.jpg");
  }
  68% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00135.jpg");
  }
  68.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00136.jpg");
  }
  69% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00137.jpg");
  }
  69.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00138.jpg");
  }
  70% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00139.jpg");
  }
  70.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00140.jpg");
  }
  71% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00141.jpg");
  }
  71.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00142.jpg");
  }
  72% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00143.jpg");
  }
  72.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00144.jpg");
  }
  73% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00145.jpg");
  }
  73.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00146.jpg");
  }
  74% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00147.jpg");
  }
  74.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00148.jpg");
  }
  75% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00149.jpg");
  }
  75.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00150.jpg");
  }
  76% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00151.jpg");
  }
  76.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00152.jpg");
  }
  77% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00153.jpg");
  }
  77.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00154.jpg");
  }
  78% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00155.jpg");
  }
  78.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00156.jpg");
  }
  79% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00157.jpg");
  }
  79.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00158.jpg");
  }
  80% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00159.jpg");
  }
  80.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00160.jpg");
  }
  81% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00161.jpg");
  }
  81.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00162.jpg");
  }
  82% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00163.jpg");
  }
  82.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00164.jpg");
  }
  83% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00165.jpg");
  }
  83.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00166.jpg");
  }
  84% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00167.jpg");
  }
  84.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00168.jpg");
  }
  85% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00169.jpg");
  }
  85.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00170.jpg");
  }
  86% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00171.jpg");
  }
  86.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00172.jpg");
  }
  87% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00173.jpg");
  }
  87.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00174.jpg");
  }
  88% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00175.jpg");
  }
  88.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00176.jpg");
  }
  89% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00177.jpg");
  }
  89.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00178.jpg");
  }
  90% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00179.jpg");
  }
  90.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00180.jpg");
  }
  91% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00181.jpg");
  }
  91.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00182.jpg");
  }
  92% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00183.jpg");
  }
  92.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00184.jpg");
  }
  93% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00185.jpg");
  }
  93.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00186.jpg");
  }
  94% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00187.jpg");
  }
  94.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00188.jpg");
  }
  95% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00189.jpg");
  }
  95.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00190.jpg");
  }
  96% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00191.jpg");
  }
  96.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00192.jpg");
  }
  97% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00193.jpg");
  }
  97.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00194.jpg");
  }
  98% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00195.jpg");
  }
  98.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00196.jpg");
  }
  99% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00197.jpg");
  }
  99.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00198.jpg");
  }
  100% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00199.jpg");
  }
}
#earth-animation-one {
  background: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00000.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00001.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00002.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00003.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00004.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00005.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00006.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00007.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00008.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00009.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00010.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00011.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00012.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00013.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00014.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00015.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00016.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00017.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00018.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00019.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00020.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00021.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00022.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00023.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00024.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00025.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00026.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00027.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00028.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00029.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00030.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00031.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00032.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00033.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00034.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00035.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00036.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00037.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00038.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00039.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00040.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00041.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00042.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00043.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00044.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00045.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00046.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00047.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00048.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00049.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00050.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00051.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00052.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00053.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00054.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00055.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00056.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00057.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00058.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00059.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00060.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00061.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00062.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00063.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00064.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00065.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00066.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00067.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00068.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00069.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00070.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00071.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00072.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00073.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00074.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00075.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00076.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00077.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00078.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00079.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00080.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00081.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00082.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00083.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00084.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00085.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00086.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00087.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00088.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00089.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00090.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00091.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00092.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00093.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00094.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00095.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00096.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00097.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00098.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00099.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00100.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00101.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00102.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00103.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00104.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00105.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00106.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00107.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00108.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00109.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00110.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00111.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00112.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00113.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00114.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00115.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00116.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00117.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00118.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00119.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00120.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00121.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00122.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00123.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00124.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00125.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00126.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00127.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00128.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00129.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00130.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00131.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00132.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00133.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00134.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00135.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00136.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00137.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00138.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00139.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00140.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00141.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00142.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00143.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00144.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00145.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00146.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00147.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00148.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00149.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00150.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00151.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00152.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00153.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00154.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00155.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00156.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00157.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00158.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00159.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00160.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00161.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00162.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00163.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00164.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00165.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00166.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00167.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00168.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00169.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00170.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00171.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00172.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00173.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00174.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00175.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00176.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00177.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00178.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00179.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00180.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00181.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00182.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00183.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00184.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00185.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00186.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00187.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00188.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00189.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00190.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00191.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00192.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00193.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00194.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00195.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00196.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00197.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00198.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_one/%E5%9C%B0%E7%90%83_00199.jpg");

  width: 100vw;
  height: 100vh;
  /*循环播放动画*/
  animation-iteration-count: infinite;
  /*动画名字*/
  animation-name: earth_one;
  /*动画一周期时间*/
  animation-duration: 13s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
#earth-animation-two {
  background: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00250.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00251.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00252.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00253.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00254.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00255.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00256.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00257.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00258.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00259.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00260.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00261.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00262.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00263.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00264.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00265.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00266.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00267.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00268.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00269.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00270.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00271.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00272.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00273.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00274.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00275.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00276.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00277.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00278.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00279.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00280.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00281.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00282.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00283.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00284.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00285.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00286.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00287.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00288.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00289.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00290.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00291.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00292.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00293.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00294.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00295.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00296.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00297.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00298.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00299.jpg");

  width: 100vw;
  height: 100vh;
  /*循环播放动画*/
  animation-iteration-count: 1;
  /*动画名字*/
  animation-name: earth_two;
  /*动画一周期时间*/
  animation-duration: 3s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@keyframes earth_two {
  0% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00250.jpg");
  }
  2% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00250.jpg");
  }
  4% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00251.jpg");
  }
  6% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00252.jpg");
  }
  8% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00253.jpg");
  }
  10% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00254.jpg");
  }
  12% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00255.jpg");
  }
  14% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00256.jpg");
  }
  16% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00257.jpg");
  }
  18% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00258.jpg");
  }
  20% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00259.jpg");
  }
  22% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00260.jpg");
  }
  24% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00261.jpg");
  }
  26% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00262.jpg");
  }
  28% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00263.jpg");
  }
  30% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00264.jpg");
  }
  32% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00265.jpg");
  }
  34% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00266.jpg");
  }
  36% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00267.jpg");
  }
  38% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00268.jpg");
  }
  40% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00269.jpg");
  }
  42% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00270.jpg");
  }
  44% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00271.jpg");
  }
  46% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00272.jpg");
  }
  48% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00273.jpg");
  }
  50% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00274.jpg");
  }
  52% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00275.jpg");
  }
  54% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00276.jpg");
  }
  56% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00277.jpg");
  }
  58% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00278.jpg");
  }
  60% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00279.jpg");
  }
  62% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00280.jpg");
  }
  64% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00281.jpg");
  }
  66% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00282.jpg");
  }
  68% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00283.jpg");
  }
  70% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00284.jpg");
  }
  72% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00285.jpg");
  }
  74% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00286.jpg");
  }
  76% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00287.jpg");
  }
  78% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00288.jpg");
  }
  80% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00289.jpg");
  }
  82% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00290.jpg");
  }
  84% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00291.jpg");
  }
  86% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00292.jpg");
  }
  88% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00293.jpg");
  }
  90% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00294.jpg");
  }
  92% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00295.jpg");
  }
  94% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00296.jpg");
  }
  96% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00297.jpg");
  }
  98% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00298.jpg");
  }
  100% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_two/%E5%9C%B0%E7%90%83_00299.jpg");
  }
}
#earth-animation-three {
  background: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00300.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00301.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00302.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00303.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00304.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00305.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00306.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00307.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00308.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00309.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00310.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00311.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00312.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00313.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00314.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00315.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00316.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00317.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00318.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00319.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00320.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00321.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00322.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00323.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00324.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00325.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00326.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00327.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00328.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00329.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00330.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00331.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00332.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00333.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00334.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00335.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00336.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00337.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00338.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00339.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00340.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00341.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00342.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00343.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00344.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00345.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00346.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00347.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00348.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00349.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00350.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00351.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00352.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00353.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00354.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00355.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00356.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00357.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00358.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00359.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00360.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00361.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00362.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00363.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00364.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00365.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00366.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00367.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00368.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00369.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00370.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00371.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00372.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00373.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00374.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00375.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00376.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00377.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00378.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00379.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00380.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00381.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00382.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00383.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00384.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00385.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00386.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00387.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00388.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00389.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00390.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00391.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00392.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00393.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00394.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00395.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00396.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00397.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00398.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00399.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00400.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00401.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00402.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00403.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00404.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00405.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00406.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00407.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00408.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00409.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00410.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00411.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00412.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00413.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00414.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00415.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00416.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00417.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00418.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00419.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00420.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00421.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00422.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00423.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00424.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00425.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00426.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00427.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00428.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00429.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00430.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00431.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00432.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00433.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00434.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00435.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00436.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00437.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00438.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00439.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00440.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00441.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00442.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00443.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00444.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00445.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00446.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00447.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00448.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00449.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00450.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00451.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00452.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00453.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00454.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00455.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00456.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00457.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00458.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00459.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00460.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00461.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00462.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00463.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00464.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00465.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00466.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00467.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00468.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00469.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00470.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00471.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00472.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00473.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00474.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00475.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00476.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00477.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00478.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00479.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00480.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00481.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00482.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00483.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00484.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00485.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00486.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00487.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00488.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00489.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00490.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00491.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00492.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00493.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00494.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00495.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00496.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00497.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00498.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00499.jpg");

  width: 100vw;
  height: 100vh;
  /*循环播放动画*/
  animation-iteration-count: infinite;
  /*动画名字*/
  animation-name: earth_three;
  /*动画一周期时间*/
  animation-duration: 13s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@keyframes earth_three {
  0% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00499.jpg");
  }
  0.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00300.jpg");
  }
  1% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00301.jpg");
  }
  1.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00302.jpg");
  }
  2% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00303.jpg");
  }
  2.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00304.jpg");
  }
  3% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00305.jpg");
  }
  3.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00306.jpg");
  }
  4% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00307.jpg");
  }
  4.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00308.jpg");
  }
  5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00309.jpg");
  }
  5.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00310.jpg");
  }
  6% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00311.jpg");
  }
  6.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00312.jpg");
  }
  7% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00313.jpg");
  }
  7.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00314.jpg");
  }
  8% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00315.jpg");
  }
  8.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00316.jpg");
  }
  9% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00317.jpg");
  }
  9.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00318.jpg");
  }
  10% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00319.jpg");
  }
  10.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00320.jpg");
  }
  11% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00321.jpg");
  }
  11.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00322.jpg");
  }
  12% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00323.jpg");
  }
  12.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00324.jpg");
  }
  13% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00325.jpg");
  }
  13.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00326.jpg");
  }
  14% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00327.jpg");
  }
  14.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00328.jpg");
  }
  15% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00329.jpg");
  }
  15.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00330.jpg");
  }
  16% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00331.jpg");
  }
  16.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00332.jpg");
  }
  17% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00333.jpg");
  }
  17.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00334.jpg");
  }
  18% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00335.jpg");
  }
  18.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00336.jpg");
  }
  19% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00337.jpg");
  }
  19.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00338.jpg");
  }
  20% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00339.jpg");
  }
  20.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00340.jpg");
  }
  21% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00341.jpg");
  }
  21.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00342.jpg");
  }
  22% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00343.jpg");
  }
  22.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00344.jpg");
  }
  23% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00345.jpg");
  }
  23.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00346.jpg");
  }
  24% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00347.jpg");
  }
  24.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00348.jpg");
  }
  25% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00349.jpg");
  }
  25.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00350.jpg");
  }
  26% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00351.jpg");
  }
  26.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00352.jpg");
  }
  27% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00353.jpg");
  }
  27.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00354.jpg");
  }
  28% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00355.jpg");
  }
  28.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00356.jpg");
  }
  29% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00357.jpg");
  }
  29.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00358.jpg");
  }
  30% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00359.jpg");
  }
  30.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00360.jpg");
  }
  31% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00361.jpg");
  }
  31.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00362.jpg");
  }
  32% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00363.jpg");
  }
  32.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00364.jpg");
  }
  33% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00365.jpg");
  }
  33.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00366.jpg");
  }
  34% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00367.jpg");
  }
  34.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00368.jpg");
  }
  35% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00369.jpg");
  }
  35.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00370.jpg");
  }
  36% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00371.jpg");
  }
  36.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00372.jpg");
  }
  37% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00373.jpg");
  }
  37.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00374.jpg");
  }
  38% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00375.jpg");
  }
  38.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00376.jpg");
  }
  39% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00377.jpg");
  }
  39.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00378.jpg");
  }
  40% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00379.jpg");
  }
  40.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00380.jpg");
  }
  41% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00381.jpg");
  }
  41.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00382.jpg");
  }
  42% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00383.jpg");
  }
  42.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00384.jpg");
  }
  43% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00385.jpg");
  }
  43.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00386.jpg");
  }
  44% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00387.jpg");
  }
  44.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00388.jpg");
  }
  45% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00389.jpg");
  }
  45.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00390.jpg");
  }
  46% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00391.jpg");
  }
  46.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00392.jpg");
  }
  47% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00393.jpg");
  }
  47.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00394.jpg");
  }
  48% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00395.jpg");
  }
  48.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00396.jpg");
  }
  49% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00397.jpg");
  }
  49.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00398.jpg");
  }
  50% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00399.jpg");
  }

  50.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00400.jpg");
  }
  51% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00401.jpg");
  }
  51.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00402.jpg");
  }
  52% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00403.jpg");
  }
  52.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00404.jpg");
  }
  53% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00405.jpg");
  }
  53.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00406.jpg");
  }
  54% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00407.jpg");
  }
  54.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00408.jpg");
  }
  55% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00409.jpg");
  }
  55.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00410.jpg");
  }
  56% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00411.jpg");
  }
  56.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00412.jpg");
  }
  57% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00413.jpg");
  }
  57.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00414.jpg");
  }
  58% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00415.jpg");
  }
  58.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00416.jpg");
  }
  59% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00417.jpg");
  }
  59.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00418.jpg");
  }
  60% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00419.jpg");
  }
  60.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00420.jpg");
  }
  61% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00421.jpg");
  }
  61.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00422.jpg");
  }
  62% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00423.jpg");
  }
  62.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00424.jpg");
  }
  63% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00425.jpg");
  }
  63.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00426.jpg");
  }
  64% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00427.jpg");
  }
  64.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00428.jpg");
  }
  65% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00429.jpg");
  }
  65.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00430.jpg");
  }
  66% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00431.jpg");
  }
  66.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00432.jpg");
  }
  67% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00433.jpg");
  }
  67.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00434.jpg");
  }
  68% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00435.jpg");
  }
  68.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00436.jpg");
  }
  69% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00437.jpg");
  }
  69.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00438.jpg");
  }
  70% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00439.jpg");
  }
  70.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00440.jpg");
  }
  71% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00441.jpg");
  }
  71.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00442.jpg");
  }
  72% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00443.jpg");
  }
  72.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00444.jpg");
  }
  73% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00445.jpg");
  }
  73.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00446.jpg");
  }
  74% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00447.jpg");
  }
  74.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00448.jpg");
  }
  75% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00449.jpg");
  }
  75.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00450.jpg");
  }
  76% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00451.jpg");
  }
  76.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00452.jpg");
  }
  77% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00453.jpg");
  }
  77.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00454.jpg");
  }
  78% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00455.jpg");
  }
  78.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00456.jpg");
  }
  79% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00457.jpg");
  }
  79.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00458.jpg");
  }
  80% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00459.jpg");
  }
  80.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00460.jpg");
  }
  81% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00461.jpg");
  }
  81.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00462.jpg");
  }
  82% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00463.jpg");
  }
  82.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00464.jpg");
  }
  83% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00465.jpg");
  }
  83.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00466.jpg");
  }
  84% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00467.jpg");
  }
  84.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00468.jpg");
  }
  85% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00469.jpg");
  }
  85.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00470.jpg");
  }
  86% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00471.jpg");
  }
  86.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00472.jpg");
  }
  87% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00473.jpg");
  }
  87.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00474.jpg");
  }
  88% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00475.jpg");
  }
  88.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00476.jpg");
  }
  89% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00477.jpg");
  }
  89.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00478.jpg");
  }
  90% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00479.jpg");
  }
  90.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00480.jpg");
  }
  91% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00481.jpg");
  }
  91.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00482.jpg");
  }
  92% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00483.jpg");
  }
  92.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00484.jpg");
  }
  93% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00485.jpg");
  }
  93.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00486.jpg");
  }
  94% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00487.jpg");
  }
  94.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00488.jpg");
  }
  95% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00489.jpg");
  }
  95.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00490.jpg");
  }
  96% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00491.jpg");
  }
  96.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00492.jpg");
  }
  97% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00493.jpg");
  }
  97.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00494.jpg");
  }
  98% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00495.jpg");
  }
  98.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00496.jpg");
  }
  99% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00497.jpg");
  }
  99.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00498.jpg");
  }
  100% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_three/%E5%9C%B0%E7%90%83_00499.jpg");
  }
}
#earth-animation-four {
  width: 100vw;
  background: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00500.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00501.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00502.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00503.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00504.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00505.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00506.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00507.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00508.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00509.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00510.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00511.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00512.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00513.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00514.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00515.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00516.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00517.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00518.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00519.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00520.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00521.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00522.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00523.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00524.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00525.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00526.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00527.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00528.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00529.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00530.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00531.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00532.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00533.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00534.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00535.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00536.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00537.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00538.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00539.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00540.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00541.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00542.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00543.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00544.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00545.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00546.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00547.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00548.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00549.jpg");
  height: 100vh;
  /*循环播放动画*/
  animation-iteration-count: 1;
  /*动画名字*/
  animation-name: earth_four;
  /*动画一周期时间*/
  animation-duration: 3s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@keyframes earth_four {
  0% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00500.jpg");
  }
  2% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00500.jpg");
  }
  4% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00501.jpg");
  }
  6% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00502.jpg");
  }
  8% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00503.jpg");
  }
  10% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00504.jpg");
  }
  12% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00505.jpg");
  }
  14% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00506.jpg");
  }
  16% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00507.jpg");
  }
  18% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00508.jpg");
  }
  20% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00509.jpg");
  }
  22% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00510.jpg");
  }
  24% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00511.jpg");
  }
  26% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00512.jpg");
  }
  28% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00513.jpg");
  }
  30% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00514.jpg");
  }
  32% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00515.jpg");
  }
  34% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00516.jpg");
  }
  36% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00517.jpg");
  }
  38% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00518.jpg");
  }
  40% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00519.jpg");
  }
  42% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00520.jpg");
  }
  44% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00521.jpg");
  }
  46% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00522.jpg");
  }
  48% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00523.jpg");
  }
  50% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00524.jpg");
  }
  52% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00525.jpg");
  }
  54% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00526.jpg");
  }
  56% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00527.jpg");
  }
  58% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00528.jpg");
  }
  60% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00529.jpg");
  }
  62% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00530.jpg");
  }
  64% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00531.jpg");
  }
  66% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00532.jpg");
  }
  68% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00533.jpg");
  }
  70% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00534.jpg");
  }
  72% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00535.jpg");
  }
  74% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00536.jpg");
  }
  76% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00537.jpg");
  }
  78% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00538.jpg");
  }
  80% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00539.jpg");
  }
  82% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00540.jpg");
  }
  84% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00541.jpg");
  }
  86% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00542.jpg");
  }
  88% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00543.jpg");
  }
  90% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00544.jpg");
  }
  92% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00545.jpg");
  }
  94% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00546.jpg");
  }
  96% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00547.jpg");
  }
  98% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00548.jpg");
  }
  100% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_four/%E5%9C%B0%E7%90%83_00549.jpg");
  }
}
#earth-animation-five {
  background: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00550.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00551.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00552.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00553.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00554.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00555.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00556.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00557.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00558.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00559.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00560.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00561.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00562.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00563.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00564.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00565.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00566.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00567.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00568.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00569.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00570.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00571.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00572.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00573.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00574.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00575.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00576.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00577.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00578.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00579.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00580.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00581.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00582.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00583.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00584.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00585.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00586.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00587.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00588.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00589.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00590.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00591.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00592.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00593.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00594.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00595.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00596.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00597.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00598.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00599.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00600.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00601.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00602.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00603.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00604.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00605.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00606.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00607.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00608.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00609.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00610.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00611.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00612.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00613.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00614.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00615.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00616.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00617.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00618.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00619.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00620.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00621.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00622.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00623.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00624.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00625.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00626.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00627.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00628.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00629.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00630.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00631.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00632.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00633.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00634.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00635.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00636.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00637.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00638.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00639.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00640.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00641.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00642.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00643.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00644.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00645.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00646.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00647.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00648.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00649.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00650.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00651.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00652.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00653.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00654.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00655.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00656.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00657.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00658.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00659.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00660.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00661.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00662.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00663.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00664.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00665.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00666.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00667.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00668.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00669.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00670.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00671.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00672.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00673.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00674.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00675.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00676.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00677.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00678.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00679.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00680.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00681.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00682.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00683.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00684.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00685.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00686.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00687.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00688.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00689.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00690.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00691.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00692.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00693.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00694.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00695.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00696.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00697.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00698.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00699.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00700.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00701.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00702.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00703.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00704.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00705.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00706.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00707.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00708.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00709.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00710.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00711.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00712.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00713.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00714.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00715.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00716.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00717.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00718.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00719.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00720.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00721.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00722.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00723.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00724.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00725.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00726.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00727.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00728.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00729.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00730.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00731.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00732.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00733.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00734.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00735.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00736.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00737.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00738.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00739.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00740.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00741.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00742.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00743.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00744.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00745.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00746.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00747.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00748.jpg"),
    url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00749.jpg");

  width: 100vw;
  height: 100vh;
  /*循环播放动画*/
  animation-iteration-count: infinite;
  /*动画名字*/
  animation-name: earth_five;
  /*动画一周期时间*/
  animation-duration: 13s;
  /*动画周期曲线*/
  animation-timing-function: linear;
  /*动画延迟*/
  /* animation-delay: 2s; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@keyframes earth_five {
  0% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00749.jpg");
  }
  0.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00550.jpg");
  }
  1% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00551.jpg");
  }
  1.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00552.jpg");
  }
  2% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00553.jpg");
  }
  2.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00554.jpg");
  }
  3% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00555.jpg");
  }
  3.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00556.jpg");
  }
  4% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00557.jpg");
  }
  4.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00558.jpg");
  }
  5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00559.jpg");
  }
  5.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00560.jpg");
  }
  6% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00561.jpg");
  }
  6.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00562.jpg");
  }
  7% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00563.jpg");
  }
  7.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00564.jpg");
  }
  8% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00565.jpg");
  }
  8.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00566.jpg");
  }
  9% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00567.jpg");
  }
  9.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00568.jpg");
  }
  10% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00569.jpg");
  }
  10.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00570.jpg");
  }
  11% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00571.jpg");
  }
  11.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00572.jpg");
  }
  12% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00573.jpg");
  }
  12.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00574.jpg");
  }
  13% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00575.jpg");
  }
  13.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00576.jpg");
  }
  14% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00577.jpg");
  }
  14.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00578.jpg");
  }
  15% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00579.jpg");
  }
  15.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00580.jpg");
  }
  16% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00581.jpg");
  }
  16.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00582.jpg");
  }
  17% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00583.jpg");
  }
  17.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00584.jpg");
  }
  18% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00585.jpg");
  }
  18.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00586.jpg");
  }
  19% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00587.jpg");
  }
  19.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00588.jpg");
  }
  20% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00589.jpg");
  }
  20.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00590.jpg");
  }
  21% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00591.jpg");
  }
  21.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00592.jpg");
  }
  22% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00593.jpg");
  }
  22.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00594.jpg");
  }
  23% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00595.jpg");
  }
  23.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00596.jpg");
  }
  24% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00597.jpg");
  }
  24.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00598.jpg");
  }
  25% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00599.jpg");
  }
  25.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00600.jpg");
  }
  26% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00601.jpg");
  }
  26.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00602.jpg");
  }
  27% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00603.jpg");
  }
  27.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00604.jpg");
  }
  28% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00605.jpg");
  }
  28.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00606.jpg");
  }
  29% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00607.jpg");
  }
  29.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00608.jpg");
  }
  30% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00609.jpg");
  }
  30.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00610.jpg");
  }
  31% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00611.jpg");
  }
  31.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00612.jpg");
  }
  32% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00613.jpg");
  }
  32.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00614.jpg");
  }
  33% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00615.jpg");
  }
  33.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00616.jpg");
  }
  34% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00617.jpg");
  }
  34.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00618.jpg");
  }
  35% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00619.jpg");
  }
  35.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00620.jpg");
  }
  36% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00621.jpg");
  }
  36.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00622.jpg");
  }
  37% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00623.jpg");
  }
  37.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00624.jpg");
  }
  38% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00625.jpg");
  }
  38.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00626.jpg");
  }
  39% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00627.jpg");
  }
  39.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00628.jpg");
  }
  40% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00629.jpg");
  }
  40.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00630.jpg");
  }
  41% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00631.jpg");
  }
  41.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00632.jpg");
  }
  42% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00633.jpg");
  }
  42.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00634.jpg");
  }
  43% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00635.jpg");
  }
  43.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00636.jpg");
  }
  44% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00637.jpg");
  }
  44.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00638.jpg");
  }
  45% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00639.jpg");
  }
  45.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00640.jpg");
  }
  46% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00641.jpg");
  }
  46.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00642.jpg");
  }
  47% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00643.jpg");
  }
  47.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00644.jpg");
  }
  48% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00645.jpg");
  }
  48.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00646.jpg");
  }
  49% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00647.jpg");
  }
  49.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00648.jpg");
  }
  50% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00649.jpg");
  }

  50.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00650.jpg");
  }
  51% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00651.jpg");
  }
  51.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00652.jpg");
  }
  52% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00653.jpg");
  }
  52.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00654.jpg");
  }
  53% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00655.jpg");
  }
  53.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00656.jpg");
  }
  54% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00657.jpg");
  }
  54.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00658.jpg");
  }
  55% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00659.jpg");
  }
  55.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00660.jpg");
  }
  56% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00661.jpg");
  }
  56.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00662.jpg");
  }
  57% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00663.jpg");
  }
  57.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00664.jpg");
  }
  58% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00665.jpg");
  }
  58.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00666.jpg");
  }
  59% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00667.jpg");
  }
  59.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00668.jpg");
  }
  60% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00669.jpg");
  }
  60.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00670.jpg");
  }
  61% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00671.jpg");
  }
  61.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00672.jpg");
  }
  62% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00673.jpg");
  }
  62.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00674.jpg");
  }
  63% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00675.jpg");
  }
  63.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00676.jpg");
  }
  64% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00677.jpg");
  }
  64.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00678.jpg");
  }
  65% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00679.jpg");
  }
  65.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00680.jpg");
  }
  66% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00681.jpg");
  }
  66.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00682.jpg");
  }
  67% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00683.jpg");
  }
  67.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00684.jpg");
  }
  68% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00685.jpg");
  }
  68.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00686.jpg");
  }
  69% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00687.jpg");
  }
  69.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00688.jpg");
  }
  70% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00689.jpg");
  }
  70.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00690.jpg");
  }
  71% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00691.jpg");
  }
  71.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00692.jpg");
  }
  72% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00693.jpg");
  }
  72.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00694.jpg");
  }
  73% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00695.jpg");
  }
  73.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00696.jpg");
  }
  74% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00697.jpg");
  }
  74.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00698.jpg");
  }
  75% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00699.jpg");
  }
  75.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00700.jpg");
  }
  76% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00701.jpg");
  }
  76.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00702.jpg");
  }
  77% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00703.jpg");
  }
  77.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00704.jpg");
  }
  78% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00705.jpg");
  }
  78.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00706.jpg");
  }
  79% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00707.jpg");
  }
  79.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00708.jpg");
  }
  80% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00709.jpg");
  }
  80.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00710.jpg");
  }
  81% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00711.jpg");
  }
  81.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00712.jpg");
  }
  82% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00713.jpg");
  }
  82.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00714.jpg");
  }
  83% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00715.jpg");
  }
  83.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00716.jpg");
  }
  84% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00717.jpg");
  }
  84.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00718.jpg");
  }
  85% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00719.jpg");
  }
  85.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00720.jpg");
  }
  86% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00721.jpg");
  }
  86.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00722.jpg");
  }
  87% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00723.jpg");
  }
  87.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00724.jpg");
  }
  88% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00725.jpg");
  }
  88.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00726.jpg");
  }
  89% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00727.jpg");
  }
  89.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00728.jpg");
  }
  90% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00729.jpg");
  }
  90.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00730.jpg");
  }
  91% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00731.jpg");
  }
  91.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00732.jpg");
  }
  92% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00733.jpg");
  }
  92.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00734.jpg");
  }
  93% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00735.jpg");
  }
  93.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00736.jpg");
  }
  94% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00737.jpg");
  }
  94.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00738.jpg");
  }
  95% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00739.jpg");
  }
  95.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00740.jpg");
  }
  96% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00741.jpg");
  }
  96.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00742.jpg");
  }
  97% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00743.jpg");
  }
  97.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00744.jpg");
  }
  98% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00745.jpg");
  }
  98.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00746.jpg");
  }
  99% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00747.jpg");
  }
  99.5% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00748.jpg");
  }
  100% {
    background-image: url("https://oss.zhiyundongneng.com/linyetanhui/animation/earth_five/%E5%9C%B0%E7%90%83_00749.jpg");
  }
}
.earth-animation-five-mask{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.22);
}
</style>