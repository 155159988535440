<template>
  <div>
    <div class="item" id="step-one-title">碳汇项目开发全流程</div>
    <div class="item-line" id="step-one-line"></div>
    <div class="item-content" id="step-one-box1">
      <span class="content-title">边界确认</span>
      <div class="content-contner" >{{itemData.border}}
      </div>
    </div>

      <div class="item-content1" id="step-one-box2">
      <span class="content-title">碳层划分</span>
      <div class="content-contner" >{{itemData.border2}}
      </div>
    </div>

      <div class="item-content2" id="step-one-box3">
      <span class="content-title">基线碳汇量、项目碳汇量计算</span>
      <div class="content-contner" >{{itemData.border3}}
      </div>
    </div>

          <div class="item-content3" id="step-one-box4">
      <span class="content-title">监测与核查</span>
      <div class="content-contner" >{{itemData.border4}}
      </div>
    </div>
    <div class="item-icon3" id="item-icon3" v-show="showItemFour==1" @click.stop="nextStep()"></div>
  </div>
</template>
<script>
import $ from "jquery"
export default {
  data(){
    return{
      itemData:{
        border:`采用全球定位系统(GPS)、北斗卫星导航系统(Compass)或其他卫星导航系 统，进行单点定位或差分技术直接测定项目地块边界的拐点坐标，或利用高分辨率的地理空间数据(如卫星影像、航片)、森林分布图、林相图、 森林经营管理规划图等，在地理信息系统(GIS)辅助下直接读取项目地块的边界坐标。`,
        border2:`为了提高碳计量的准 确性和精度、降低在一定精度要求下所需监测的样地数量，需要对项目区进行分层。
      分层的目的是为了降低层内变异性，增加层间变异性，同时也能降低监测成本。
      分层的关键是看同一层内是否具有近似的碳储量变化和相同的计量参数。碳层划分包括“基线碳 层划分”和“项目碳层划分”。`,
        border3:`基线碳汇量是在没有拟议项目活动的情况下，项目边界内所有碳库中碳储量的变化 之和。 基线情景下各碳层林木生物质碳储量的变化采用“碳储量变化法”进行估算。 
      项目碳汇量等于项目边界内所选碳库的碳储量年变化量减去项目边界内温室气体排放量的增加量。
      森林经营碳汇项目活动的减排量(即人为净温室气体汇清除)等于项目碳汇量减去基线碳汇量。`,
        border4:`项目参与方须基于固定样地的连续测定方法，采用碳储量变化法，测定和估计相关碳库中碳储量的变化。
      在各项目碳层内，样地的空间分配采用随机起点、系统布点的布 设方案。
      首次监测(生物量和枯死木)在项目开始前进行，首次核查与审定同时进行。 项目开始后的监测和核查的间隔期为 3~10 年。`,
      },
    }
  },
  mounted(){
    $("#step-one-title").fadeIn(1000,()=>{})
    $("#step-one-line").fadeIn(1000,()=>{
      
      $("#step-one-box1").fadeIn(1000,()=>{

        $("#step-one-box2").fadeIn(1000,()=>{

          $("#step-one-box3").fadeIn(1000,()=>{
  
            $("#step-one-box4").fadeIn(1000,()=>{
              
              $("#item-icon3").fadeIn(1000,()=>{
                
              })
            })
          })
        })
      })
    })
  },
  methods:{
    nextStep(){
      this.$emit('changeItemNumberEmit')
    }
  }
}
</script>
<style scoped>
.item{
  position:absolute;
  left: 1287px;
  top: 125px;
  width: 306px;
height: 34px;
font-size: 34px;
font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
font-weight: bold;
color: #D1FEE9;
line-height: 34px;
text-shadow: 0px 0px 4px rgba(255,255,255,0.5000), 0px 0px 2px #FFFFFF;
display: none;
}
.item-line{
   position:absolute;
  width: 400px;
height: 28px;
 background-image: url("../../assets/Navbar/横线.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
left: 1240px;
top: 173px;
display: none;
}
.item-content{
  position:absolute;
  width: 800px;
  height: 126px;
  left: 1040px;
  top: 211px;
display: none;
}
.content-title{
  position: relative;
  width: 800px;
  height: 64px;
  line-height: 64px;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #D1FEE9;
  text-shadow: 0px 0px 4px rgba(255,255,255,0.5000), 0px 0px 2px #FFFFFF;
  display: block;
  background-image: url("../../assets/step_one/弹窗1bg@2x (6).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content-contner{
  width: 760px;
  height: 74px;
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #FFC147;
  line-height: 24px;
  position: relative;
  /* top: 64px; */
  background-image: url("../../assets/step_one/弹窗1bg@2x (7).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px 20px;
  text-align: left;
  
/* left: 30px; */
}
.item-icon{
position: relative;
width: 60px;
height: 60px;
background-image: url("../../assets/Navbar/返回@2x.png");
background-size: 100% 100%;
background-repeat: no-repeat;
left: 1410px;
top: 347px;
display: none;
z-index: 999;
cursor: pointer;
}
.item-content1{
  position:absolute;
  width: 800px;
  height: 126px;
  left: 1040px;
  top: 399px;
  display: none;
}
.item-icon1{
position: relative;
width: 60px;
height: 60px;
background-image: url("../../assets/Navbar/返回@2x.png");
background-size: 100% 100%;
background-repeat: no-repeat;
left: 1410px;
top: 495px;
cursor: pointer;
}
.item-content2{
  position:absolute;
  width: 800px;
  height: 126px;
  left: 1040px;
  top: 587px;
display: none;
}
.item-icon2{
position: relative;
width: 60px;
height: 60px;
background-image: url("../../assets/Navbar/返回@2x.png");
background-size: 100% 100%;
background-repeat: no-repeat;
left: 1410px;
top: 640px;
cursor: pointer;
}
.item-content3{
  position:absolute;
  width: 800px;
  height: 126px;
  left: 1040px;
  top: 775px;
  display: none;
}
.item-icon3{
position: relative;
width: 60px;
height: 60px;
background-image: url("../../assets/Navbar/返回@2x.png");
background-size: 100% 100%;
background-repeat: no-repeat;
left: 1410px;
top: 955px;
cursor: pointer;
display: none;
}
</style>