<template>
  <div class="step-two">
    <div class="step-two-root" id="step-two-root"></div>
    <div class="step-two-root-line" id="step-two-root-line"></div>
    <div class="step-two-root-one" id="step-two-root-one"></div>
    <div class="step-two-root-two" id="step-two-root-two"></div>

    <div
      class="step-two-root-arrow-one"
      id="step-two-root-arrow-one"
      v-show="showRootOne == 1"
      @click.stop="changeRootNumber1()"
    ></div>
    <div
      class="step-two-root-arrow-two"
      id="step-two-root-arrow-two"
      v-show="showRootTwo == 1"
      @click.stop="changeRootNumber2()"
    ></div>
    <div
      class="step-two-root-arrow-line-one"
      id="step-two-root-arrow-line-one"
      v-show="showRootTwo == 1"
    ></div>
    <div
      class="step-two-root-arrow-line-two"
      id="step-two-root-arrow-line-two"
      v-show="showRootTwo == 1"
    ></div>

    <div
      class="step-two-drawer-one-container"
      id="step-two-drawer-one-container"
      v-show="showDrawerOne == 1"
    >
      <div class="step-two-drawer-one-left">
        <div class="step-two-drawer-one-left-img"></div>
        <div class="step-two-drawer-one-left-title">传统方法</div>
      </div>
      <div id="step-two-drawer-one-right-container">
        <div class="step-two-drawer-one-right" id="step-two-drawer-one-right">
          <div class="step-two-drawer-one-right-line"></div>
          <div
            class="step-two-drawer-one-right-unit"
            id="step-two-drawer-one-right-unit"
            @click.stop="goToFrame1()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>人工测定样地内所有活立的胸径（DBH）和（或）树高（H）</div>
            </div>
          </div>
          <div
            class="step-two-drawer-two-right-unit"
            id="step-two-drawer-two-right-unit"
            @click.stop="goToFrame2()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>生物量方程计算</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="step-two-drawer-two-container"
      id="step-two-drawer-two-container"
      v-show="showDrawerTwo == 1"
    >
      <div class="step-two-drawer-two-left">
        <div class="step-two-drawer-one-left-img"></div>
        <div class="step-two-drawer-two-left-title">无人机激光雷达遥感</div>
      </div>
      <div id="step-two-drawer-one-right-container-two">
        <div
          class="step-two-drawer-one-right"
          id="step-two-drawer-one-right-two"
        >
          <div class="step-two-drawer-two-right-line"></div>
          <div
            class="step-two-drawer-three-right-unit"
            @click.stop="goToFrame4()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>激光雷达测绘</div>
            </div>
          </div>
          <div class="step-two-drawer-three-right-line"></div>
          <div
            class="step-two-drawer-four-right-unit"
            @click.stop="goToFrame5()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>单木分割</div>
            </div>
          </div>
          <div class="step-two-drawer-four-right-line"></div>
          <div
            class="step-two-drawer-five-right-unit"
            @click.stop="goToFrame6()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>结构参数提取</div>
            </div>
          </div>
          <div
            class="step-two-drawer-sex-right-unit"
            @click.stop="goToFrame7()"
          >
            <div class="step-two-drawer-one-right-content">
              <div>机器学习模型</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="step-two-drawer-three-container"
      id="step-two-drawer-three-container"
      v-show="showDrawerThree == 1"
      @click.stop="goToFrame3()"
    >
      <div class="step-two-drawer-three-left">
        <div class="step-two-drawer-one-left-img"></div>
        <div class="step-two-drawer-one-left-title">多源卫星数据</div>
      </div>
    </div>
    <div
      class="step-two-root-arrow-three"
      id="step-two-root-arrow-three"
      v-show="showRootThree == 1"
    ></div>
    <div
      class="step-two-drawer-four-container"
      id="step-two-drawer-four-container"
    >
      <div class="step-two-drawer-four-left">
        <div class="step-two-drawer-one-left-img"></div>
        <div class="step-two-drawer-four-left-title">无人机激光雷达遥感</div>
      </div>
    </div>
    <div id="step-two-root-left-container">
      <div class="step-two-drawer-one-line"></div>
      <div class="step-two-drawer-two-line"></div>

      <div class="step-two-root-three"></div>
      <div class="step-two-root-arrow-four"></div>
      <div class="step-two-root-three-line"></div>

      <div class="step-two-root-four"></div>
    </div>
    <el-image-viewer
      v-if="isShowImageViewer"
      @close="closeViewer"
      teleported
      :url-list="srcList"
      :initial-index="swiperImgIndex"
    />
    <!-- 弹框 -->
      <div class="mask" v-show="goFrame2 == 1|| goFrame2 == 2" @click="handleCloseFrame()"></div>
    <div class="frame" v-show="goFrame2 == 1" id="frame">
        <!-- 轮播图 -->
      <div class="swipper" v-show="frameNumber == 1">
        <div class="swiper-container" ref="mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              @click="changeImgIndex(0)"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
            >
              <img src="../../assets/step_two/8771657587628_.pic.jpg" alt="" />
            </div>
            <div
              class="swiper-slide"
              @click="changeImgIndex(1)"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
            >
              <img src="../../assets/step_two/8781657587629_.pic.jpg" alt="" />
            </div>
            <div
              class="swiper-slide"
              @click="changeImgIndex(2)"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
            >
              <img src="../../assets/step_two/8791657587629_.pic.jpg" alt="" />
            </div>
            <div
              class="swiper-slide"
              @click="changeImgIndex(3)"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
            >
              <img src="../../assets/step_two/8801657587629_.pic.jpg" alt="" />
            </div>
           
          </div>
        </div>
      </div>

      <div class="frame-content" v-show="frameNumber == 2">  
  <div>{{}}</div>
      <img @click="showImgViewer(1)" src="../../assets/step_two/公式.png" alt="" /></div>

    </div>
    <div class="frame1" v-show="goFrame2 == 2" id="frame1">
      <div class="video-content" v-show="frameNumber == 3">
        <!-- <video id="myVideo" class="video-js vjs-big-play-centered">
            <source src="https://oss.zhiyundongneng.com/linyetanhui/monitor/%E9%A1%B5%E9%9D%A2%E4%B8%8A%E7%9A%84%E8%A7%86%E9%A2%91.mp4" type="video/mp4">
        </video> -->
        <div class="video-content2"  v-html="videohtml"></div>
        <!-- <video src="../../assets/step_two/无人机激光雷达遥感植被三维特征提取.mp4" id="video-container" :autoplay="autoplay" class="video-item"></video> -->
      </div>
<div class="frame-content1" v-show="frameNumber == 4">点云单木分割包括以下步骤：去噪、滤波、生成数字高程模型（Digital Elevation Model, DEM）、数字表面模型（Digital Surface Model, DSM）和冠层高度模型（Canopy Height Model, CHM）、基于CHM进行单木分割。获取单木位置、树高、冠幅直径、冠幅面积、冠幅体积和其他垂直结构参数。 <img @click="showImgViewer(2)" src="../../assets/step_two/图片1.png" alt="" /></div>
<div class="frame-content2" v-show="frameNumber == 5"> <img @click="showImgViewer(3)" src="../../assets/step_two/结构参数提取.jpg" alt="" /></div>
<div class="frame-content4" v-show="frameNumber == 6"> <img @click="showImgViewer(4)" src="../../assets/step_two/6.png" alt="" /></div>
<div class="frame-content3" v-show="frameNumber == 7"> 多源卫星数据融合将同一地区的多源遥感影像数据加以智能化合成，产生比单一信源更精确、更完全、更可靠的估计和判断。它的优点是运行的鲁棒性，提高影像的空间分解力和清晰度，增强解译和动态监测能力，有效提高遥感影像数据的利用率。 新一代的光学传感器（Sentinel-2）、LiDAR传感器（ICESat-2、GEDI和MOLI）和SAR传感器(BIOMASS、SAOCOM和Sentinel-1）数据融合将使碳储量估算获得前所未有的空间和时间分辨率以及准确性。</div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      myPlayer: null,
      srcList:[],
      tempSrcList: [
        [
          require("../../assets/step_two/8771657587628_.pic.jpg"),
          require("../../assets/step_two/8781657587629_.pic.jpg"),
          require("../../assets/step_two/8791657587629_.pic.jpg"),
          require("../../assets/step_two/8801657587629_.pic.jpg")
        ],
        require("../../assets/step_two/公式.png"),
        require("../../assets/step_two/图片1.png"),
        require("../../assets/step_two/结构参数提取.jpg"),
        require("../../assets/step_two/6.png")
      ],
      isShowImageViewer:false,
      //箭头
      showRootOne: 1,
      showRootTwo: 0,
      showRootThree: 0,
      //内容框
      showDrawerOne: 0,
      showDrawerTwo: 0,
      showDrawerThree: 0,
      // 弹框
      goFrame1: 0,
      goFrame2: 0,
      SwiperData: [{}],
      swiper: null,
      frameNumber: 1,
      autoPlay: false,
      swiperImgIndex: 0,
      videohtml: ''
    };
  },
  methods: {
    closeViewer(){
      console.log('123');
      this.isShowImageViewer = false
    },
    changeImgIndex(e){
      // console.log();
      this.swiperImgIndex = e
      this.srcList = this.tempSrcList[0]
      this.isShowImageViewer = true
    },
    showImgViewer(e){
      this.swiperImgIndex = 0
      this.srcList = [this.tempSrcList[e]]
      this.isShowImageViewer = true
    },
    getVideo() {
      this.myPlayer = this.$video(document.getElementById("myVideo"), {
          controls: true,           
          autoplay: false,//自动播放属性,muted:静音播放
          //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: "auto",//none不预加载任何数据，直到用户开始播放才开始下载
          width: "340px",
          height: "192px",
          loop: false,//循环
    //poster:""//在视频开始播放之前显示的图像的URL
      });
    },
    handleMouseEnter() {
      console.log("enter", this.swiper);
      this.swiper.stopAutoplay();
    },
    handleMouseLeave() {
      console.log("out");
      this.swiper.startAutoplay();
    },
    initSwiper() {
      this.$nextTick(() => {
        this.swiper = new Swiper(".swiper-container", {
          direction: "horizontal", //水平滑动
          autoplay: 2000, //自动播放时间
          // autoplay:{
          //   stopOnLastSlide:true
          // },
          speed: 2000, //速度
          autoplayDisableOnInteraction: false,
          loop: true,
          autoplay: true,
          centeredSlides: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          slidesPerView: "auto",
          // loopedSlides: 'auto',
          // slidesPerView: 4, //显示多少个
          spaceBetween: 0, //间距20px
        });
      });

      // swiper.init();
      //    window.addEventListener("resize",function (){
      //     this.swiper.resize();
      // });
    },
    changeRootNumber1() {
      this.showDrawerOne = 1;
      this.showDrawerTwo = 1;
      this.showRootTwo = 1;
      $("#step-two-root-arrow-one").animate(
        { top: "39%" },
        1000,
        "swing",
        () => {
          $("#step-two-root-arrow-line-one").fadeIn(1000, () => {});
        }
      );
      $("#step-two-root-arrow-two").animate(
        { top: "60.1%" },
        1000,
        "swing",
        () => {
          $("#step-two-root-arrow-line-two").fadeIn(1000, () => {
            $("#step-two-drawer-one-container").fadeIn(1000, () => {});
            $("#step-two-drawer-two-container").fadeIn(1000, () => {
              $("#step-two-drawer-one-right-container").animate(
                { left: "33.5%" },
                800,
                "swing",
                () => {}
              );
              $("#step-two-drawer-one-right").animate(
                { opacity: 1 },
                2000,
                "swing",
                () => {}
              );
              $("#step-two-drawer-one-right-container-two").animate(
                { left: "33.5%" },
                800,
                "swing",
                () => {}
              );
              $("#step-two-drawer-one-right-two").animate(
                { opacity: 1 },
                2000,
                "swing",
                () => {
                  $("#step-two-root-arrow-three").fadeIn(1000, () => {
                    $("#step-two-drawer-four-container").fadeIn(1000, () => {
                      $("#step-two-root-left-container").fadeIn(1000, () => {});
                    });
                  });
                }
              );
            });
            $("#step-two-drawer-three-container").fadeIn(1000, () => {});
          });
        }
      );
    },
    changeRootNumber2() {
      this.showDrawerThree = 1;
      this.showRootThree = 1;
    },
    //弹框
    goToFrame1() {
      let that = this;
      this.goFrame2 = 1;
      $("#frame").css({ left: "43.4%" });
      $("#frame").fadeIn(500, () => {});
      console.log(this.swiper);
      that.frameNumber = 1;
      this.swiper.autoplayPaused = false;
      that.swiper.startAutoplay();
      // this.initSwiper();
    },
    goToFrame2() {
      this.goFrame2 = 1;

       this.frameNumber=2
      $("#frame").css({ left: "57.3%" });
      $("#frame").fadeIn(500, () => {
      });
    },
    goToFrame3() {
      this.goFrame2 = 2;
 this.frameNumber=7
      $("#frame1").css({ left: "30.5%", top: "68.5%" });
      $("#frame1").fadeIn(500, () => {
      });
    },
    handleCloseFrame(){
      this.goFrame2=0
      if (this.myPlayer) {
        this.myPlayer.dispose(); // 该方法会重置videojs的内部状态并移除dom
        this.videohtml = '';
      }
    },
    goToFrame4() {
      this.videohtml = `<video id="myVideo" class="video-js vjs-big-play-centered">
      <source src="https://oss.zhiyundongneng.com/linyetanhui/monitor/%E9%A1%B5%E9%9D%A2%E4%B8%8A%E7%9A%84%E8%A7%86%E9%A2%91.mp4" type="video/mp4">
      </video>`
      setTimeout(() => {
        this.$nextTick(()=>{
          this.getVideo();
        })
      }, 300);
      this.goFrame2 = 2;
        this.frameNumber=3
      $("#frame1").css({ left: "40.1%", top: "51.9%" });
      $("#frame1").fadeIn(500, () => {
      });
    },
    goToFrame5() {
      this.goFrame2 = 2;
        this.frameNumber=4
      $("#frame1").css({ left: "46.6%", top: "51.9%" });
      $("#frame1").fadeIn(500, () => {
      });
    },
    goToFrame6() {
      this.goFrame2 = 2;
        this.frameNumber=5
      $("#frame1").css({ left: "53.1%", top: "51.9%" });
      $("#frame1").fadeIn(500, () => {
      });
    },
    goToFrame7() {
      this.goFrame2 = 2;
      this.frameNumber=6
      $("#frame1").css({ left: "59.6%", top: "51.9%" });
      $("#frame1").fadeIn(500, () => {
       
      });
    },
  },
  mounted() {
    this.initSwiper();
    //  this.getData()
    this.$nextTick(() => {
      
      $("#step-two-root").css({display:'none'});
      $("#step-two-root-line").css({display:'none'});
      $("#step-two-root-one").css({display:'none'});
      $("#step-two-root-two").css({display:'none'});
      $("#step-two-root-arrow-one").css({display:'none'});
      $("#step-two-root-left-container").css({display:'none'});
      $("#step-two-drawer-four-container").css({display:'none'});
      $("#step-two-root").fadeIn(1000, () => {
        $("#step-two-root-line").fadeIn(1000, () => {
          $("#step-two-root-one").fadeIn(1000, () => {
            $("#step-two-root-arrow-one").fadeIn(1000, () => {});
          });
          $("#step-two-root-two").fadeIn(1000, () => {});
        });
      });
    });
  },
  destroyed() {
    console.log('xiezaile ')
  },
};
</script>

<style scoped>
.step-two {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: #fff; */
}
/* 弹窗1bg@2x.png */
/* 魔方处理后@2x.png */
/* 返回备份@2x.png */
/* 分割线1@2xmini.png */
/* 编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x (1).png */
.step-two-root {
  width: 240px;
  height: 126px;
  position: absolute;
  background-image: url("../../assets/step_two/弹窗1bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 501px;
  left: 24px;
  display: none;
}
.step-two-root-line {
  width: 28px;
  height: 313px;
  position: absolute;
  background-image: url("../../assets/Navbar/分割线备份 3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 408px;
  left: 277px;
  display: none;
}
.step-two-root-one {
  width: 200px;
  height: 80px;
  position: absolute;
  background-image: url("../../assets/step_two/弹窗1bg@2x2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 410px;
  left: 316px;
  display: none;
}
.step-two-root-two {
  width: 200px;
  height: 80px;
  position: absolute;
  background-image: url("../../assets/step_two/弹窗1bg@2x3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 640px;
  left: 316px;
  display: none;
}
.step-two-root-arrow-one {
  width: 60px;
  height: 60px;
  position: absolute;
  background-image: url("../../assets/step_two/返回备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* top: 420px; */
  top: 49.5%;
  left: 528px;
  cursor: pointer;
  display: none;
}
.step-two-root-arrow-two {
  width: 60px;
  height: 60px;
  position: absolute;
  background-image: url("../../assets/step_two/返回备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 49.5%;
  left: 528px;
  cursor: pointer;
}
.step-two-root-arrow-line-one {
  width: 28px;
  height: 180px;
  background-image: url("../../assets/Navbar/分割线备份.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 360px;
  left: 600px;
  display: none;
}
.step-two-root-arrow-line-two {
  width: 28px;
  height: 80px;
  background-image: url("../../assets/Navbar/分割线备份 4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 640px;
  left: 600px;
  display: none;
}
.step-two-root-arrow-three {
  width: 60px;
  height: 60px;
  position: absolute;
  background-image: url("../../assets/Navbar/返回备份 5@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 650px;
  left: 985px;
}
.step-two-drawer-one-container {
  width: 742px;
  height: 75px;
  position: relative;
  top: 360px;
  left: 646px;
  display: none;
}
.step-two-drawer-one-left {
  width: 250px;
  height: 75px;
  background-image: url("../../assets/step_two/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x (1).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.step-two-drawer-one-left-img {
  width: 74px;
  height: 61px;
  background-image: url("../../assets/step_two/魔方处理后@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 7px;
  left: 19px;
}
.step-two-drawer-one-right-line {
  width: 29px;
  height: 75px;
  background-image: url("../../assets/step_two/分割线1@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 231px;
}
.step-two-drawer-two-right-line {
  width: 29px;
  height: 75px;
  background-image: url("../../assets/step_two/分割线1@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 108px;
}
.step-two-drawer-three-right-line {
  width: 29px;
  height: 75px;
  background-image: url("../../assets/step_two/分割线1@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 231px;
}
.step-two-drawer-four-right-line {
  width: 29px;
  height: 75px;
  background-image: url("../../assets/step_two/分割线1@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 354px;
}
.step-two-drawer-one-left-title {
  width: 126px;
  height: 42px;
  position: absolute;
  top: 17px;
  left: 110px;
  font-size: 21px;
  font-family: MicrosoftYaHeiSemibold;
  color: #d1fee9;
  line-height: 42px;
}
#step-two-drawer-one-right-container {
  width: 492px;
  height: 75px;
  position: absolute;
  left: -12.6%;
}
#step-two-drawer-one-right-container-two {
  width: 492px;
  height: 75px;
  position: absolute;
  left: -12.6%;
}
.step-two-drawer-one-right {
  width: 492px;
  height: 75px;
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  /* background-color: #fff; */
  z-index: 999;
  opacity: 0;
}
.step-two-drawer-one-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-two-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-three-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-four-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-five-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-sex-right-unit {
  flex: 1;
  background: linear-gradient(90deg, rgba(0, 197, 197, 0.5) 0%, #01572e 100%);
  cursor: pointer;
}
.step-two-drawer-one-right-content {
  /* width: 230px; */
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.step-two-drawer-one-right-content div {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 18px;
}

.step-two-drawer-two-container {
  width: 742px;
  height: 75px;
  position: relative;
  top: 390px;
  left: 646px;
  display: none;
}
.step-two-drawer-two-left {
  width: 250px;
  height: 75px;
  background-image: url("../../assets/step_two/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x (1).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
}
.step-two-drawer-two-left-title {
  width: 126px;
  height: 42px;
  position: absolute;
  top: 17px;
  left: 110px;
  font-size: 21px;
  font-family: MicrosoftYaHeiSemibold;
  color: #d1fee9;
  line-height: 21px;
  text-align: left;
}

.step-two-drawer-three-container {
  /* width: 742px;
  height: 75px; */
  cursor: pointer;
}
.step-two-drawer-three-left {
  width: 250px;
  height: 75px;
  background-image: url("../../assets/step_two/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x (1).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 643px;
  left: 646px;
  z-index: 9;
}
.step-two-drawer-four-container {
  /* width: 742px;
  height: 75px; */
  display: none;
}
.step-two-drawer-four-left {
  width: 250px;
  height: 75px;
  background-image: url("../../assets/step_two/编组 14 + 编组 14 + 编组 14 + 编组 14 蒙版@2x (1).png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 643px;
  left: 1130px;
}

.step-two-drawer-four-left-title {
  width: 126px;
  height: 42px;
  position: absolute;
  top: 10px;
  left: 110px;
  font-size: 21px;
  font-family: MicrosoftYaHeiSemibold;
  color: #d1fee9;
  /* line-height: 42px; */
}
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transform: 0.5;
}
.fade-enter-to {
  opacity: 1;
}
#step-two-root-left-container {
  display: none;
}
.step-two-drawer-one-line {
  width: 28px;
  height: 180px;
  background-image: url("../../assets/Navbar/分割线备份.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 360px;
  left: 1398px;
}
.step-two-drawer-two-line {
  width: 28px;
  height: 80px;
  background-image: url("../../assets/Navbar/分割线备份 4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 640px;
  left: 1398px;
}
.step-two-root-three {
  width: 200px;
  height: 126px;
  position: absolute;
  background-image: url("../../assets/step_two/弹窗1bg@2x4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 394px;
  left: 1436px;
}
.step-two-root-arrow-four {
  width: 60px;
  height: 60px;
  position: absolute;
  background-image: url("../../assets/step_two/返回备份@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 650px;
  left: 1506px;
}
.step-two-root-three-line {
  width: 28px;
  height: 313px;
  position: absolute;
  background-image: url("../../assets/Navbar/分割线备份 3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 408px;
  left: 1649px;
}
.step-two-root-four {
  width: 200px;
  height: 126px;
  position: absolute;
  background-image: url("../../assets/step_two/弹窗1bg@2x5.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  top: 501px;
  left: 1696px;
}
.mask{
  width: 1920px;
  height: 1080px;
  background-color: transparent;
  /* background-color: #fff; */
  position: absolute;
  top: 0;
  left: 0;
}
.frame {
  position: absolute;
  width: 372px;
  height: 238px;
  background-image: url("../../assets/Navbar/矩形@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: 833px;
  top: 100px;
  /* display: none; */
}
.frame1 {
  position: absolute;
  width: 372px;
  height: 238px;
  background-image: url("../../assets/step_two/矩形备份 4@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: 585px;
  top: 740px;
  z-index: 99;
  /* display: none; */
}
/* 轮播图 */
.swipper {
  width: 372px;
  height: 238px;
  z-index: 9999;
}
.roll-content {
  width: 100%;
  height: 192px;
  margin-top: 16px;
  /* background-color: #c7fdf3; */
}

.roll-number {
  width: calc(100% - 0px);
  height: 18px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffc147;
  line-height: 18px;
  text-shadow: 0px 0px 2px #ffbf14;
  margin-top: 30px;
  /* margin-left: 14%; */
  text-align: center;
}
.roll-place {
  width: calc(100% - 12px);
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #d1fee9;
  line-height: 12px;
  margin-top: 10px;
  padding-left: 7%;
  text-align: center;
}

.pc-banner {
  width: 340px;
  height: 192px;
  float: left;
}
@media screen and (max-width: 340px) {
  .pc-banner {
    background-size: 340px;
  }
}
.swiper-container {
  width: 100%;
  height: 192px;
  margin-top: 16px;
}
.swiper-slide {
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -ms-transition: transform 1s;
  -o-transition: transform 1s;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  /* background-image: url("../../assets/TopRight/编组 18@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  width: 340px !important;
  height: 192px !important;
}
.swiper-container .swiper-wrapper .swiper-slide img {
  width: 340px !important;
  height: 192px !important;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.video-content {
  width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
}
.video-content2 {
  position: absolute;
  width: 340px !important;
  height: 192px !important;
  top: 30px;
  left: 16px;
}
/* #myVideo{
  position: absolute;
  width: 340px !important;
  height: 192px !important;
  top: 30px;
  left: 16px;
} */
.frame-content{
   width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
}
.frame-content img{
   width: 340px !important;
  height: 192px !important;
  margin-left: 16px;
}
.frame-content1{
   width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
  color: #fff;
  text-align: left;
  padding-left: 20px;
  padding-top: 3px;
}
.frame-content1 img{
   width: 340px !important;
  height: 132px !important;
  margin-left: -3px;
  margin-top: 3px;
}
.frame-content2{
   width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
}
.frame-content2 img{
   width: 340px !important;
  height: 192px !important;
  margin-left: 16px;
  margin-top: 16px;
}
.frame-content3{
   width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
  color: #fff;
  text-align: left;
  padding-left: 20px;
  padding-top: 9px;
  font-size: 15px;
}
.frame-content3 img{
   width: 340px !important;
  height: 192px !important;
  margin-left: 16px;
  margin-top: 16px;
}
.frame-content4{
   width: 340px !important;
  height: 192px !important;
  margin-top: 16px;
  
}
.frame-content4 img{
   width: 340px !important;
  height: 192px !important;
  margin-left: 16px;
  margin-top: 16px;
}
.video-item {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  color: #fff;
  text-align: right;
  margin-right: 10px;
}
</style>
<style>
.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>
